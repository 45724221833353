import React from "react";
import { isEmpty } from "lodash";
import Grid from "@mui/material/Grid";
import { useMount, useUnmount } from "react-use";
import { navigate } from "gatsby";

import { Main } from "~/layouts";
import { useCart, useClearCart } from '~/hooks/cart.hooks';
import { track } from "~/core/analytics";
import { HeroTitle } from "~/components/hero";
import { Subheadline, Body } from "~/components/typography";
import { ArrowIcon } from "~/components/icons";
import { Button } from "~/components/button";
import { Footer } from "~/components/footer";

const ThankYouPage = () => {
  const { items: cartItems } = useCart();
  const clearCart = useClearCart();

  useMount(() => {
    if (!cartItems.length) {
      return navigate('/');
    }

    const trackingMetadata = {
      currency: 'USD',
      shipping: 0,
      tax: 0,
      value: cartItems.reduce((total, { price }) => price.value + total, 0),
    };

    track.purchase(
      cartItems.map((p, i) => ({
        id: p.sku,
        name: p.title,
        brand: p.vendor,
        variant: isEmpty(p.metadata) ? 'Blank' : 'Personalized',
        list_position: i + 1,
        quantity: p.quantity,
        price: p.unitPrice,
      })),
      trackingMetadata,
    );
  });

  useUnmount(() => {
    if (cartItems.length) {
      clearCart();
    }
  });

  if (!cartItems.length) {
    return null;
  }

  return (
    <Main>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <HeroTitle
          title="Thank you!"
          align="center"
          bgColor="transparent"
        />
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={8} sm={6} style={{ paddingBottom: "12rem", textAlign: "center" }}>
          <Subheadline>Your order has been successfully placed</Subheadline>
          <Body>If you have questions about your order, you can email us at <strong><a href="mailto:hello@thegoodsnail.com">hello@thegoodsnail.com</a></strong>.</Body>
          <br />
          <br />
          <Button
            to="/search"
            label="Continue Shopping"
            icon={<ArrowIcon />}
          />
        </Grid>
      </Grid>
      <Footer />
    </Main>
  );
}

export default ThankYouPage;
